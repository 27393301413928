.mx_PasswordDialog {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000000000;
  background: linear-gradient(200.79deg, #4630a0 7.33%, #2f6fa0 58.49%, #09d8a2);
}

.mx_PasswordDialog_Wrap {
  width: 80%;
  max-width: 400px;
  position: absolute;
  left: 50%;
  top: 45%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  background-color: #fff;
  border-radius: 25px;
  padding: 50px 30px;
}

.mx_PasswordDialog_Wrap_title {
  font-size: 1.25rem;
  font-family: FontBold;
  color: #040405;
}

.mx_PasswordDialog_Wrap_subTitle {
  margin-top: 10px;
  font-size: 1rem;
  font-family: FontRegular;
  color: #707083;
}

.mx_Field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
  position: relative;
  margin: 1em 0;
  border-radius: 4px;
  -webkit-transition: border-color .25s;
  transition: border-color .25s;
  border: 1px solid #e7e7e7;
}

.mx_PasswordDialog_Wrap_passwordInput {
  width: 100%;
  font-size: 1.4rem;
  color: #040405;
  font-family: FontRegular;
  border-radius: 9999px!important;
  height: 45px;
  border: none!important;
  padding: 1px;
  background: #dadfe5;
}

.mx_Field input, .mx_Field select, .mx_Field textarea {
  font-weight: 400;
  font-family: Inter,Twemoji,Apple Color Emoji,Segoe UI Emoji,Arial,Helvetica,Sans-Serif,Noto Color Emoji;
  font-size: 1.4rem;
  border: none;
  border-radius: 4px;
  padding: 8px 9px;
  color: #2e2f32;
  background-color: #fff;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 0;
}

.mx_PasswordDialog_Wrap_passwordInput input {
  border-radius: 9999px!important;
  padding-left: 15px!important;
}

input[type=password], input[type=text], textarea {
  color: #2e2f32;
}

input[type=password], input[type=search], input[type=text] {
  padding: 9px;
  font-family: Inter,Twemoji,Apple Color Emoji,Segoe UI Emoji,Arial,Helvetica,Sans-Serif,Noto Color Emoji;
  font-size: 1rem;
  font-weight: 600;
  min-width: 0;
  outline: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.mx_Field label {
  -webkit-transition: font-size .25s ease-out .1s,color .25s ease-out .1s,top .25s ease-out .1s,background-color .25s ease-out .1s;
  transition: font-size .25s ease-out .1s,color .25s ease-out .1s,top .25s ease-out .1s,background-color .25s ease-out .1s;
  color: #2e2f32;
  background-color: transparent;
  font-size: 1.4rem;
  position: absolute;
  left: 10px;
  top: -2px;
  margin: 7px 8px;
  padding: 2px;
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 20px);
}

.mx_PasswordDialog_Wrap_passwordInput label {
  top: 2px!important;
}

.mx_PasswordDialog_Wrap_signButton {
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #09d8a2;
  border-radius: 9999px;
  -webkit-transition: all .5s;
  transition: all .5s;
  font-family: FontBold;
  font-size: 1rem;
  color: #fff;
  border: none;
}

.mx_PasswordDialog_Wrap_signButton:hover {
  border-radius: 9999px;
  background-color: #4630a0;
}

.mx_PasswordDialog_Wrap {
  span {
    display: block;
    color: red !important;
    margin-top: 1rem;
    &.d-none {
      display: none;
    }
  }
}



